.el-date-editor.el-input[data-v-5b9acefc], .el-date-editor.el-input__inner[data-v-5b9acefc] {
  width: 180px !important;
}
.save_box[data-v-5b9acefc] {
  margin: 0 auto;
  margin-top: 30px;
  text-align: right;
}
.center[data-v-5b9acefc] {
  text-align: center;
  color: #333 !important;
}
.weight[data-v-5b9acefc] {
  font-size: 13px !important;
}
.left[data-v-5b9acefc] {
  text-align: left;
}
.right[data-v-5b9acefc] {
  text-align: right;
}
.left_k[data-v-5b9acefc] {
  text-indent: 41px;
}
.content[data-v-5b9acefc] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  background-color: #fff;
}
.content span[data-v-5b9acefc] {
  line-height: 26px;
  font-size: 13px;
}
.content tr[data-v-5b9acefc] {
  padding: 0;
  margin: 0;
}
.content td[data-v-5b9acefc] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 26px;
  padding: 0 6px;
  color: #000;
  font-size: 13px;
}
.content input[data-v-5b9acefc] {
  width: 100%;
  line-height: 26px;
  padding: 0 6px;
  font-size: 13px;
}
.bg_color[data-v-5b9acefc] {
  background-color: #d6e2ea;
}