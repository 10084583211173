.el-date-editor.el-input[data-v-48c040d2], .el-date-editor.el-input__inner[data-v-48c040d2] {
  width: 180px !important;
}
.save_box[data-v-48c040d2] {
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content[data-v-48c040d2] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  background-color: #fff;
}
.content span[data-v-48c040d2] {
  line-height: 28px;
  font-size: 13px;
}
.content tr[data-v-48c040d2] {
  padding: 0;
  margin: 0;
}
.content td[data-v-48c040d2] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 25px;
  padding: 0 6px;
  color: #000;
  font-size: 13px;
}
.content_head[data-v-48c040d2] {
  margin-top: 10px;
  border-bottom: none;
}
.content_body[data-v-48c040d2] {
  border-top: none;
}
.left_k[data-v-48c040d2] {
  text-indent: 2em;
}
.left_b[data-v-48c040d2] {
  text-indent: 4em;
}
.left_x[data-v-48c040d2] {
  text-indent: 5em;
}
.left_d[data-v-48c040d2] {
  text-indent: 8em;
}
.center[data-v-48c040d2] {
  text-align: center;
}
.left[data-v-48c040d2] {
  text-align: left;
}
.right[data-v-48c040d2] {
  text-align: right;
}
.weight[data-v-48c040d2] {
  font-weight: 700;
}
.row_box[data-v-48c040d2] {
  border-bottom: 1px solid #eee;
}
.big_box4[data-v-48c040d2] {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly[data-v-48c040d2] {
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  cursor: pointer;
}
.bg_color[data-v-48c040d2] {
  background-color: #d6e2ea;
}