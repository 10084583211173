.right_btns[data-v-61bf8964] {
  position: absolute;
  top: 7px;
  right: 18px;
  z-index: 99;
}
[data-v-61bf8964] .el-tabs__content {
  background-color: #f1f1f1 !important;
}
.finance_main[data-v-61bf8964] {
  background-color: #f1f1f1;
  position: relative;
}